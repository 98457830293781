<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showForm"
      max-width="1200px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div id="map">
            <GmapMap
              ref="mapRef"
              :zoom="zoom"
              :center="center"
              style="width: 100%; height: 600px"
              :key="kmap"
            >
              <GmapMarker
                :key="index"
                v-for="(pin, index) in wells_list"
                :position="{ lat: pin.latitude, lng: pin.longitude }"
                ,
                :label="{
                  text: pin.wellname,
                  fontFamily: 'Material Icons',
                  color: 'black',
                  fontSize: '14px',
                }"
                :icon="getMarker(pin.id)"
                @click="select_well(pin)"
              >
              </GmapMarker>
            </GmapMap>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            ref="bselect"
            class="ma-1"
            color="blue darken-1"
            @click.stop="select"
          >
            Select
          </v-btn>
          <v-btn color="blue darken-1" @click.stop="closeForm" class="ma-1">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import * as VueGoogleMaps from "vue2-google-maps";

export default {
  props: {
    showForm: Boolean,
    well_selected: Object,
    wells: Array,
  },

  data: () => ({
    center: { lat: 31.3044084, lng: 2.8463832 },
    kmap: 2000,
    zoom: 15,
    wells_list: [],
    wells_selected: [],
    Shape: {},
    polygonArray: [],
  }),
  computed: {
    google: VueGoogleMaps.gmapApi,
  },
  created() {},
  mounted() {
    this.wells_list = this.wells;

    this.initMap(this.Shape, this.polygonArray);

    if (this.well_selected)
      this.center = {
        lat: this.well_selected.latitude,
        lng: this.well_selected.longitude,
      };
  },

  methods: {
    setMarker_color(color) {
      let d =
        "M-1.5471.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z";
      if (color != "red")
        d =
          "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z";
      return {
        path: d,
        fillColor: color,
        fillOpacity: 0.6,
        strokeWeight: 0,
        strokeWidth: 5,
        rotation: 0,
        scale: 2,
        labelOrigin: new this.google.maps.Point(0, 20),
      };
    },

    getMarker(id) {
      if (id == this.well_selected.id) return this.setMarker_color("blue");
      else if (this.wells_selected.findIndex((w) => w.id == id) >= 0)
        return this.setMarker_color("green");
      else return this.setMarker_color("red");
    },
    closeForm() {
      // this.kmap++;
      this.$emit("close");
    },
    select() {
      this.$emit("select", this.get_selectedwells(this.Shape));
    },
    select_well(well) {
      if (well.id != this.well_selected.id) {
        let k = this.wells_selected.findIndex((w) => w.id == well.id);
        if (k < 0) {
          this.wells_selected.push(well);
        } else {
          this.wells_selected.splice(k, 1);
        }
        //  this.getMarker(well.id);
      }
    },
    get_selectedwells(bounds) {
      if (bounds.bound) {
        let a = bounds.bound.Lh;
        let b = bounds.bound.bi ? bounds.bound.bi : bounds.bound.ci;
        this.wells_list
          .filter(
            (x) =>
              x.longitude <= a.hi &&
              x.longitude >= a.lo &&
              x.latitude <= b.hi &&
              x.latitude >= b.lo
          )
          .forEach((w) => {
            if (this.wells_selected.findIndex((x) => x.id == w.id) < 0) {
              this.wells_selected.push(w);
            }
          });
      }
      //add the well analysed
      if (this.wells_selected.length > 0) {
        if (
          this.wells_selected.findIndex((x) => x.id == this.well_selected.id) <
          0
        )
          this.wells_selected.push(this.well_selected);
      }
      return this.wells_selected;
    },
    async initMap(Shape, shs) {
      //  if (Shape) Shape = null;
      if (this.$refs.mapRef) {
        this.$refs.mapRef.$mapPromise.then(() => {
          const drawingManager = new this.google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingControlOptions: {
              position: this.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [
                // this.google.maps.drawing.OverlayType.MARKER,
                // this.google.maps.drawing.OverlayType.CIRCLE,
                // this.google.maps.drawing.OverlayType.POLYGON,
                // this.google.maps.drawing.OverlayType.POLYLINE,
                this.google.maps.drawing.OverlayType.RECTANGLE,
              ],
            },
            polygonOptions: {
              strokeWeight: 1,
              fillOpacity: 0,
              editable: true,
              zIndex: 1,
            },
            rectangleOptions: {
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.25,
              draggable: true,
              geodesic: true,
              editable: true,
            },
          });
          let map = this.$refs.mapRef.$mapObject;
          drawingManager.setMap(map);

          //   drawingManager.setDrawingMode(
          //     this.google.maps.drawing.OverlayType.RECTANGLE
          //   );
          this.google.maps.event.addListener(
            drawingManager,
            "overlaycomplete",
            function (e) {
              shs.forEach((x) => x.setMap(null));
              shs = [];
              if (e.type != "marker") {
                drawingManager.setDrawingMode(null);
                Shape.bound = e.overlay.getBounds();
                shs.push(e.overlay);
                //  Shape = e.overlay;
              }
            }
          );
        });
      }
    },
  },
};
</script>
